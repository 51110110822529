// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-funny-parenting-posts-index-js": () => import("./../../../src/pages/funny-parenting-posts/index.js" /* webpackChunkName: "component---src-pages-funny-parenting-posts-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-helpful-post-js": () => import("./../../../src/templates/HelpfulPost.js" /* webpackChunkName: "component---src-templates-helpful-post-js" */),
  "component---src-templates-helpful-post-list-js": () => import("./../../../src/templates/HelpfulPostList.js" /* webpackChunkName: "component---src-templates-helpful-post-list-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/InfoPage.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/TagPage.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

